@font-face {
  font-family: 'PTSerif';
  src: url('./fonts/PT/ptserif-regular-webfont.woff2') format('woff2'),
       url('./fonts/PT/ptserif-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PTSerif';
  src: url('./fonts/PT/ptserif-bold-webfont.woff2') format('woff2'),
       url('./fonts/PT/ptserif-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

html {
  width: 100vw;
  height: 100vh;
  height: stretch;
  height: -webkit-fill-available;
}

#root {
  width: 100vw;
  height: 100vh;
  height: stretch;
}

body {
  margin: 0;
  font-family: 'PTSerif', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  height: stretch;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

input::placeholder {
  color: #adb4bd;
  font-size: 17px;
}

.fill-available {
  width: 100vw;
  height: 100vh;
  height: stretch;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

button {
  font-family: inherit;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@keyframes fade {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slide {
  from {
    transform: translate3d(40px, 0, 0);
  }
  to {
    transform: translate3d(-341px, 0, 0);
  }
}
